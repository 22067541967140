@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.maintenance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #333;
    color: white;
    padding: 20px;

    h1 {
        font-size: 2rem;
        margin-top: 10px;
    }

    p {
        font-size: 1.2rem;
        max-width: 600px;
    }

    a {
        color: #1da1f2;
        font-weight: bold;
    }

    .icon {
        font-size: 50px;
        color: #ffcc00;
    }
}
